import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import BankrollBalance from '../BankrollBalance/BankrollBalance';
import HotwalletBalance from '../BankrollBalance/HotwalletBalance';
import StakewalletBalance from '../BankrollBalance/StakewalletBalance';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class Tokenomics extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const chartColors = [
      "#4A61DD",
      "#6699CC",
      "#3546a6",
      "#9ba6de",
    ];

    const tokenDistData = {
      maintainAspectRatio: false,
      responsive: false,
      labels: ["Presale - 40%", "Liquidity - 28%", "Team Vesting - 17%", "Unlock - 15%"],
      datasets: [
        {
          data: [40, 28, 17, 15],
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors
        }
      ]
    };

    const options = {
      plugins: {
        legend: {
          usePointStyle: true,
          pointStyle: 'circle',
          display: true,
          labels: {
            usePointStyle: true,
            color: '#fff'
          },
        },
        datalabels: {
          display: true,
          color: "white",
          formatter: function (context, chart_obj) {
            return context + "%"
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return tooltipItem.label;
            }
          }
        },
      },
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    };

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      'center-content'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'RXCG Tokenomics',
      paragraph: 'Detailed financial and operational overview of the RXCG token ecosystem.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div>          
              <h2 style={{ color: '#fff', fontSize: '20px', fontWeight: '700', lineHeight: '1.2' }}>Token Smart contract details</h2>
              <p style={{ marginBottom: '5px', color: '#bbb', fontSize: '0.85rem', wordBreak: 'break-word', whiteSpace: 'nowrap' }}>
              Contract: <a target="_blank" href="https://bscscan.com/token/0x7c59a57fc16eac270421b74615c4bc009ecd486d" style={{ color: '#4A61DD', textDecoration: 'underline' }}>
                0x7c59a57fc16eac270421b74615c4bc009ecd486d
              </a>
              </p>
              <p style={{ marginBottom: '5px', color: '#bbb', fontSize: '0.85rem' }}>Buy Tax: <strong>0%</strong></p>
              <p style={{ marginBottom: '5px', color: '#bbb', fontSize: '0.85rem' }}>Sell Tax: <strong>0%</strong></p>
              <p style={{ marginBottom: '5px', color: '#bbb', fontSize: '0.85rem' }}>Token Name: <strong>$RXCG</strong></p>
              <p style={{ marginBottom: '5px', color: '#bbb', fontSize: '0.85rem' }}>Total Supply: <strong>100,000,000</strong></p>
              <p style={{ marginBottom: '20px', color: '#bbb', fontSize: '0.85rem' }}>Network: <strong>Binance Smart Chain (BSC)</strong></p>
              <h5 style={{ color: '#fff', marginBottom: '10px' }}>Balances</h5>
              <BankrollBalance />
              <HotwalletBalance />
              <StakewalletBalance />
            </div>                  
         </div>
        </div>
      </section>
    );
  }
}

Tokenomics.propTypes = propTypes;
Tokenomics.defaultProps = defaultProps;

export default Tokenomics;
